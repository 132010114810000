<template>
  <BasicSelect
    v-model="valueLocal"
    :values="templates"
    value-key="id"
    label-prop="name"
    key-prop="id"
    class="TemplatePicker"
    data-testid="TemplatePicker"
    popper-class="TemplatePickerDropdown"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import BasicSelect from '@hypefactors/shared/js/components/core/BasicSelect'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

export default {
  name: 'TemplatePicker',

  components: {
    BasicSelect
  },

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  computed: {
    ...mapGetters('measure', ['templates']),

    valueLocal: generateComputedSetterWithEmit('value', 'input')
  }
}
</script>
