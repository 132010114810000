<template>
  <VButton
    :loading="isLoading"
    data-testid="previewReportTrigger"
    @click="$emit('click')"
  >
    {{ $t('general.preview') }}
  </VButton>
</template>

<script>
import VButton from '@hypefactors/shared/js/components/core/VButton'

export default {
  name: 'ButtonPreviewReport',

  components: {
    VButton
  },

  props: {
    isLoading: {
      type: Boolean,
      required: true
    }
  }
}
</script>
