<template>
  <div class="ReportSettings" data-testid="ReportSettings">
    <div>
      <Heading size="3" :serif="false" class="m-b-none">
        <HelpTooltip :content="$t('components.the_report_base_settings.tooltip')" />

        {{ $t('components.the_report_base_settings.heading') }}
      </Heading>

      <div class="has-text-weight-bold">
        {{ $t('components.the_report_base_settings.description') }}
      </div>
    </div>

    <VSeparator />

    <div class="columns m-b-xl">
      <div class="column is-narrow">
        <div>
          {{ $t('components.the_report_base_settings.reporter_logo_label') }}
        </div>

        <CroppieWrapper
          ref="reporterLogo"
          :value="form.reporterLogo"
          :min-width="120"
          :min-height="120"
          :settings="croppieSettings"
          :crop-options="croppieSettings.cropOptions"
          :enforce-boundary="false"
          :is-editable="false"
          text-size="5"
          entity="ReporterLogo"
          @input="handleLogoChange"
          @save="uploadLogo"
          @removed="updateLogoInStoreFromForm"
        >
          {{ $t('components.the_report_base_settings.upload_reporter_logo') }}
        </CroppieWrapper>
      </div>

      <div class="column is-3-tablet is-2-desktop">
        <div class="m-b-xs">
          {{ $t('components.the_report_base_settings.main_color_picker_label') }}

          <ColorPicker
            v-model="primaryColorLocal"
            data-testid="BaseSettings__PrimaryColor"
            class="SettingsColorPicker has-background-white border-default"
            placeholder="ex: #fafafa"
            has-input
          />
        </div>

        <div>
          {{ $t('components.the_report_base_settings.main_color_inverted_picker_label') }}

          <ColorPicker
            v-model="primaryColorInvertLocal"
            data-testid="BaseSettings__SecondaryColor"
            placeholder="ex: #fafafa"
            class="SettingsColorPicker has-background-white border-default"
            has-input
          />
        </div>
      </div>

      <div class="column is-3-tablet is-2-desktop">
        <div class="m-b-xs">
          {{ $t('components.the_report_base_settings.font_label') }}

          <BasicSelect
            v-model="fontFamilyLocal"
            :values="customFontFamilies"
            value-prop="value"
            label-prop="label"
            key-prop="value"
            clearable
            popper-class="BaseSettings__CustomFontDropdown"
            data-testid="BaseSettings__CustomFont"
          />
        </div>

        <div>
          {{ $t('general.language') }}

          <LanguagePicker
            v-model="languageLocal"
            :placeholder="$t('forms.choose_language')"
            popper-class="Preferences_LanguageModal"
            class="Preferences_LanguagePicker"
            show-supported-only
            show-language-variations
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import BasicSelect from '@hypefactors/shared/js/components/core/BasicSelect'
import ColorPicker from '@hypefactors/shared/js/components/core/ColorPicker'
import CroppieWrapper from '@hypefactors/shared/js/components/core/CroppieWrapper'
import Heading from '@hypefactors/shared/js/components/core/Heading'
import LanguagePicker from '@hypefactors/shared/js/components/core/LanguagePicker'
import HelpTooltip from '@hypefactors/shared/js/components/core/HelpTooltip'
import VSeparator from '@hypefactors/shared/js/components/core/VSeparator'

import { HF_CUSTOM_STYLE_FONT_FAMILIES } from '@hypefactors/shared/js/constants/fonts'
import { generateComputedSetterWithMethod } from '@hypefactors/shared/js/utils/componentUtilities'
import { croppieDataFactory, croppieDefaultSettings, mergeCroppieData } from '@hypefactors/shared/js/factories/croppie'

export default {
  name: 'ReportSettings',

  components: {
    BasicSelect,
    ColorPicker,
    CroppieWrapper,
    Heading,
    HelpTooltip,
    LanguagePicker,
    VSeparator
  },

  data () {
    return {
      form: {
        reporterLogo: croppieDataFactory()
      },
      croppieSettings: croppieDefaultSettings(270, 110, 'rectangle')
    }
  },
  computed: {
    ...mapGetters('measure', [
      'language',
      'fontFamily',
      'reporterLogo',
      'primaryColor',
      'primaryColorInvert'
    ]),

    customFontFamilies: HF_CUSTOM_STYLE_FONT_FAMILIES,

    languageLocal: generateComputedSetterWithMethod('language', 'setLanguage'),
    fontFamilyLocal: generateComputedSetterWithMethod('fontFamily', 'setFontFamily'),
    primaryColorLocal: generateComputedSetterWithMethod('primaryColor', 'setPrimaryColor'),
    primaryColorInvertLocal: generateComputedSetterWithMethod('primaryColorInvert', 'setPrimaryColorInvert')
  },
  watch: {
    reporterLogo: {
      immediate: true,
      handler (logo) {
        this.handleLogoChange({ cropped: logo })
      }
    }
  },
  methods: {
    ...mapActions('measure', [
      'setFontFamily',
      'setReporterLogoUrl',
      'setLanguage',
      'setPrimaryColor',
      'setPrimaryColorInvert'
    ]),

    async uploadLogo () {
      await this.$refs.reporterLogo.uploadImage()
      this.updateLogoInStoreFromForm()
    },
    handleLogoChange (data) {
      this.form.reporterLogo = mergeCroppieData({
        cropped: data.cropped,
        original: data.cropped
      })
    },
    updateLogoInStoreFromForm () {
      this.setReporterLogoUrl(this.form.reporterLogo.cropped)
    }
  }
}
</script>

<style lang='scss'>
.ReportSettings .SettingsColorPicker {
  .ext-color-picker__chosen-color {
    border-right-color: $hf__input-border-color;
  }
}
</style>
