<template>
  <div
    class="ReportHeader p-a-m has-background-black is-hidden-print"
    data-testid="ReportHeader"
  >
    <div class="container">
      <div class="has-text-right m-b-l">
        <img :src="$asset('/images/branding/hf_logo_white.svg')" class="is-width-16" alt="">
      </div>

      <div class="columns is-aligned-middle">
        <div class="column">
          <editable-field
            v-model="$v.name.$model"
            :validator="$v.name"
            :placeholder="$t('components.the_report_manager_head.report_name_placeholder')"
            :empty-text="$t('components.the_report_manager_head.report_name_label')"
            :label="$t('components.the_report_manager_head.report_name_label')"
            name="name"
            auto-stop-edit
            floating-label
            inline-placeholder
            data-testid="nameInput"
            class="ReportName__Input"
            control-class="ReportName__Input"
            control="EditableControlInput"
          />
        </div>

        <div class="column m-l-a is-narrow">
          <ButtonGenerateReport
            class="is-white"
            data-testid="generateReportTrigger"
            :is-loading="isGenerating"
            @click="generateReport"
          />
        </div>

        <div class="column is-narrow">
          <ButtonPreviewReport
            class="button is-white is-outlined"
            data-testid="previewReportTrigger"
            :is-loading="isLoadingPreview"
            @click="previewReport"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import { ReportApiService } from '@hypefactors/shared/js/services/api/ReportApiService'
import { removeFalsyValuesFormObject } from '@hypefactors/shared/js/utils/UrlUtils'

import EditableField from '@hypefactors/shared/js/components/forms/controls/EditableField'

import ButtonGenerateReport from '@/components/measure/ButtonGenerateReport'
import ButtonPreviewReport from '@/components/measure/ButtonPreviewReport'

export default {
  name: 'ReportHeader',

  components: {
    ButtonGenerateReport,
    ButtonPreviewReport,
    EditableField
  },

  validations: {
    name: { required }
  },

  data () {
    return {
      isGenerating: false,
      isLoadingPreview: false
    }
  },

  computed: {
    ...mapGetters('coverage', ['appliedCoverageFilters']),
    ...mapState('measure', ['report', 'name', 'settings', 'hiddenPages']),

    hasChanged () {
      const { name, settings, hiddenPages, appliedCoverageFilters } = this

      return {
        name,
        settings,
        hiddenPages,
        appliedCoverageFilters
      }
    },

    name: {
      get () {
        return this.$store.state.measure.name
      },
      set (value) {
        this.setName(value)
      }
    }
  },

  watch: {
    hasChanged: {
      deep: true,
      async handler () {
        await this.saveReport()
      }
    }
  },

  methods: {
    ...mapActions('measure', ['setName']),

    async saveReport () {
      const payload = {
        name: this.name,
        settings: removeFalsyValuesFormObject(this.settings),
        filters: removeFalsyValuesFormObject(this.appliedCoverageFilters),
        hidden_pages: removeFalsyValuesFormObject(this.hiddenPages)
      }

      await ReportApiService.update(this.report.id, payload)
    },

    async generateReport () {
      if (!this.name) {
        return this.$v.name.$touch()
      }

      this.isGenerating = true

      try {
        await this.$confirm(this.$t('components.generate_report_button.confirm_generation'))

        await this.saveReport()

        await ReportApiService.generate(this.report.id, {
          name: this.name
        })

        this.$notify.success({
          duration: 10000, // 10 seconds
          message: this.$t('components.generate_report_button.report_saved')
        })

        return this.$router.push({
          name: 'measure.view',
          params: { reportId: this.report.id }
        })
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isGenerating = false
      }
    },

    async previewReport () {
      this.isLoadingPreview = true

      try {
        await this.saveReport()

        const url = this.$getUrl(`/measure/${this.report.id}/view`, 'report')

        return this.$redirectTo(url, 0, 'preview_report')
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isLoadingPreview = false
      }
    }
  }
}
</script>

<style lang="scss">
.ReportHeader {
  .ext-color-picker input::placeholder {
    color: $white;
    opacity: 1;
  }

  .ReportName__Input {
    &, input {
      font-size: $size-2;
      color: $hf__color-white;
      font-weight: $weight-bold;
    }

    .EditableField__placeholder {
      color: $hf__color-white;
    }

    input::placeholder {
      color: $hf__color-white !important;
    }
  }
}
</style>
