<template>
  <div
    v-if="isReady"
    class="has-background-grey-light"
  >
    <ReportHeader />

    <!-- TODO: Convert into a ReportFilters -->
    <BaseCoverageFilters
      class="is-position-sticky p-h-l z-index-1"
      data-testid="ReportFilters"
    >
      <template #primary>
        <TemplatePicker
          class="is-width-auto"
          @input="handleTemplateSelection"
        />
      </template>

      <template #tertiary>
        <DashboardFiltersV2Item>
          <VButton
            class="is-black"
            data-testid="openReportTemplateManagerTrigger"
            @click="isTemplateModalVisible = true"
          >
            {{ $t('pages.measure_edit.create_template') }}
          </VButton>
        </DashboardFiltersV2Item>
      </template>
    </BaseCoverageFilters>

    <div class="p-a-l">
      <ReportSettings />

      <div :class="computedClasses" data-testid="reportPagesContainer">
        <CoverPage />

        <KeyMetricsByHfValue />

        <KeyMetricsByImpressions />

        <KeyMetricsByClippings />

        <FactsMarkets />

        <MediaTypeByHfValue />

        <MediaTypeByImpressions />

        <MediaTypeByClippings />

        <Branding />

        <MediaCategoryByHfValue />

        <MediaCategoryByImpressions />

        <MediaCategoryByClippings />

        <Sentiment />

        <MetricsMap />

        <TopClippings />

        <CoverageTopClippingsDataProvider
          #default="{ data }"
          :fetch-when="bootstrapped"
          :limit="300"
          class="Page__content-body"
          name="clipping_preview"
        >
          <template v-if="data.length">
            <ClippingsDivider />

            <ClippingPage
              v-for="clipping in data"
              :key="clipping.id"
              :clipping="clipping"
            />
          </template>
        </CoverageTopClippingsDataProvider>
      </div>
    </div>

    <TheReportTemplateManagerModal
      :visible.sync="isTemplateModalVisible"
      @deleted="handleReportTemplateChange"
      @created="handleReportTemplateChange"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { ReportApiService } from '@hypefactors/shared/js/services/api/ReportApiService'

import BaseCoverageFilters from '@hypefactors/shared/js/components/clippings/BaseCoverageFilters'
import CoverageTopClippingsDataProvider from '@hypefactors/shared/js/components/tables/CoverageTopClippingsDataProvider'
import DashboardFiltersV2Item from '@hypefactors/shared/js/components/filters/DashboardFiltersV2Item'
import VButton from '@hypefactors/shared/js/components/core/VButton'

import ReportHeader from '@/components/measure/ReportHeader'
import TemplatePicker from '@/components/measure/TemplatePicker'
import ReportSettings from '@/components/measure/ReportSettings' // TODO: Rename this which is basically the settings to choose colors etc..
import TheReportTemplateManagerModal from '@/components/measure/TheReportTemplateManagerModal' // TODO: Rename this component

// TODO: Rename the following components to have a stricter
// naming pattern since these are "pages" aka "sections".
import Branding from '@/components/measure/sections/Branding'
import ClippingsDivider from '@/components/measure/sections/ClippingsDivider' // TODO: This is an exception for renaming...
import ClippingPage from '@/components/measure/sections/ClippingPage'
import CoverPage from '@/components/measure/sections/CoverPage'
import FactsMarkets from '@/components/measure/sections/FactsMarkets'
import KeyMetricsByHfValue from '@/components/measure/sections/KeyMetricsByHfValue'
import KeyMetricsByClippings from '@/components/measure/sections/KeyMetricsByClippings'
import KeyMetricsByImpressions from '@/components/measure/sections/KeyMetricsByImpressions'
import MediaCategoryByHfValue from '@/components/measure/sections/MediaCategoryByHfValue'
import MediaCategoryByClippings from '@/components/measure/sections/MediaCategoryByClippings'
import MediaCategoryByImpressions from '@/components/measure/sections/MediaCategoryByImpressions'
import MediaTypeByHfValue from '@/components/measure/sections/MediaTypeByHfValue'
import MediaTypeByClippings from '@/components/measure/sections/MediaTypeByClippings'
import MediaTypeByImpressions from '@/components/measure/sections/MediaTypeByImpressions'
import MetricsMap from '@/components/measure/sections/MetricsMap'
import Sentiment from '@/components/measure/sections/Sentiment'
import TopClippings from '@/components/measure/sections/TopClippings'

export default {
  name: 'Manage',

  components: {
    BaseCoverageFilters,
    CoverageTopClippingsDataProvider,
    DashboardFiltersV2Item,
    VButton,

    TemplatePicker,
    ReportSettings,
    TheReportTemplateManagerModal,
    ReportHeader,

    Branding,
    ClippingsDivider,
    ClippingPage,
    CoverPage,
    FactsMarkets,
    KeyMetricsByHfValue,
    KeyMetricsByImpressions,
    KeyMetricsByClippings,
    MediaCategoryByHfValue,
    MediaCategoryByClippings,
    MediaCategoryByImpressions,
    MediaTypeByHfValue,
    MediaTypeByClippings,
    MediaTypeByImpressions,
    MetricsMap,
    Sentiment,
    TopClippings
  },

  data () {
    return {
      isReady: false,
      isTemplateModalVisible: false
    }
  },

  computed: {
    ...mapState('coverage', ['bootstrapped']),
    ...mapGetters('measure', ['fontFamily']),

    computedClasses () {
      return {
        [`has-font-${this.fontFamily}`]: this.fontFamily
      }
    }
  },

  async created () {
    const loading = this.$loading({
      lock: true,
      text: this.$t('Preparing the Report')
    })

    await this.fetchTemplates()

    this.isReady = true

    loading.close()
  },

  methods: {
    ...mapActions('measure', [
      'setTemplates',
      'syncUiFromTemplate'
    ]),

    async fetchTemplates () {
      await ReportApiService.fetchReportTemplates(this.activeBrandId)
        .then((templates) => {
          this.setTemplates(templates)
        })
    },

    async handleTemplateSelection (reportTemplate) {
      await this.$confirm(this.$t('pages.measure_edit.confirm_template_selection'))

      await this.syncUiFromTemplate(reportTemplate)
    },

    /**
     * Force a refresh on the template picker
     */
    async handleReportTemplateChange () {
      await this.fetchTemplates()
    }
  }
}
</script>
