<template>
  <el-dialog
    :visible.sync="visibleLocal"
    :title="$t('components.the_report_template_manager_modal.modal_title')"
    class="TheReportTemplateManagerModal"
  >
    <Heading size="5" :serif="false">
      {{ $t('components.the_report_template_manager_modal.title') }}
    </Heading>

    <form @submit.prevent="createTemplate">
      <div class="columns is-aligned-middle">
        <div class="column">
          <FormField
            #default="{ validator }"
            :validator="$v.form.name"
            :label="$t('components.the_report_template_manager_modal.name_input_label')"
            name="name"
          >
            <input
              v-model="validator.$model"
              type="text"
              data-testid="reportTemplateNameInput"
              class="input"
            >
          </FormField>
        </div>
        <div class="column is-narrow">
          <VButton
            :loading="isCreating"
            class="is-black m-t-m"
            type="submit"
            data-testid="saveReportTemplateTrigger"
          >
            {{ $t('forms.save') }}
          </VButton>
        </div>
      </div>
    </form>

    <VSeparator class="m-v-l" color="grey" />

    <Heading size="5" color="danger" :serif="false">
      {{ $t('components.the_report_template_manager_modal.danger_zone') }}
    </Heading>

    <div v-loading="isFetching" class="columns is-aligned-middle">
      <div class="column">
        <BasicSelect
          v-model="templateToDelete"
          :values="templates"
          value-prop="id"
          label-prop="name"
          key-prop="id"
          data-testid="reportTemplateDeleteSelect"
          popper-class="reportTemplateDeleteSelectDropdown"
        />
      </div>
      <div class="column has-text-right">
        <VButton
          class="is-danger"
          type="button"
          :loading="isDeleting"
          :disabled="!templateToDelete"
          data-testid="deleteReportTemplateTrigger"
          @click="deleteTemplate"
        >
          {{ $t('general.delete') }}
        </VButton>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'
import BasicSelect from '@hypefactors/shared/js/components/core/BasicSelect'
import FormField from '@hypefactors/shared/js/components/forms/FormField'
import Heading from '@hypefactors/shared/js/components/core/Heading'
import VButton from '@hypefactors/shared/js/components/core/VButton'
import VSeparator from '@hypefactors/shared/js/components/core/VSeparator'

import { removeFalsyValuesFormObject } from '@hypefactors/shared/js/utils/UrlUtils'
import { ReportApiService } from '@hypefactors/shared/js/services/api/ReportApiService'
import { removeOneByKey } from '@hypefactors/shared/js/utils/arrayUtils'

export default {
  name: 'TheReportTemplateManagerModal',

  components: {
    BasicSelect,
    FormField,
    Heading,
    VButton,
    VSeparator
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        name: ''
      },
      templateToDelete: null,
      templates: [],
      isCreating: false,
      isFetching: false,
      isDeleting: false
    }
  },
  validations: {
    form: {
      name: { required }
    }
  },
  computed: {
    ...mapState('measure', ['settings', 'hiddenPages']),
    ...mapGetters('coverage', ['appliedCoverageFilters']),
    visibleLocal: generateComputedSetterWithEmit('visible', 'update:visible')
  },
  watch: {
    visible (v) {
      // fetch the templates after we open the modal
      if (v) this.fetchTemplates()
    }
  },
  methods: {
    async fetchTemplates () {
      try {
        this.isFetching = true
        this.templates = await ReportApiService.fetchReportTemplates(this.activeBrandId)
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isFetching = false
      }
    },
    async createTemplate () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      try {
        this.isCreating = true
        const payload = {
          name: this.form.name,
          settings: removeFalsyValuesFormObject(this.settings),
          filters: removeFalsyValuesFormObject(this.appliedCoverageFilters),
          hidden_pages: removeFalsyValuesFormObject(this.hiddenPages)
        }
        const template = await ReportApiService.createReportTemplate(this.activeBrandId, payload)
        this.templates.push(template)

        this.form.name = ''
        this.$v.form.$reset()

        this.$emit('created', template)
        this.$notify.success(this.$t('components.the_report_template_manager_modal.created_notification'))
      } catch (err) {
        this.$displayRequestError(err)
      } finally {
        this.isCreating = false
      }
    },
    async deleteTemplate () {
      try {
        await this.$confirm(this.$t('components.the_report_template_manager_modal.confirm_deletion'))
        this.isDeleting = true
        await ReportApiService.deleteReportTemplate(this.activeBrandId, this.templateToDelete)
        this.templates = removeOneByKey(this.templates, 'id', this.templateToDelete)
        this.templateToDelete = null
        this.$emit('deleted')
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isDeleting = false
      }
    }
  }
}
</script>
